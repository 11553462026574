<template>
    <el-row :gutter="10">
      <el-col class="card-view-number-multiple-col" :span="8">
        <div class="target-text"><b>A (Min)</b>: {{ scale.target_a }}</div>
      </el-col>
      <el-col class="card-view-number-multiple-col" :span="8">
        <div class="target-text"><b>B (Цель)</b>: {{ scale.target_b }}</div>
      </el-col>
      <el-col class="card-view-number-multiple-col" :span="8">
        <div class="target-text"><b>C (Max)</b>: {{ scale.target_c }}</div>
      </el-col>
    </el-row>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";

export default {
  name: "card-view",
  mixins: [dynamicScaleComponent],
  components: {},

  props: {},

  data() {
    return {
      scale: {
        target_a: null,
        target_b: null,
        target_c: null,
        pay_a: null,
        pay_b: null,
        pay_c: null,
      },
    }
  },
  mounted() {

  },
  computed: {},
  methods: {}
}
</script>

<style>
.target-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: justify;
  flex-grow: 1;
  flex-basis: 0;
}
</style>